div {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* Kepler GL Map Controls */
.map-draw-controls,
.map-locale-controls {
    display: none;
}

/* AntD Tabs */
.ant-tabs-nav-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ant-tabs-nav-list > .ant-tabs-tab {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #f5f5f5 !important;
}

.ant-tabs-nav-list > .ant-tabs-tab.ant-tabs-tab-active {
    background: #ffffff !important;
}

/* Linear Progress bar Styles */
.linear-progress {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 6px;
    display: block;
    width: 100%;
    background-color: #87ceeb;
    border-radius: 2px;
    background-clip: padding-box;
    overflow: hidden;
}

.linear-progress .linear-progress-indeterminate {
    background-color: #1890ff;
}

.linear-progress .linear-progress-indeterminate:before {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
    infinite;
    animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.linear-progress .linear-progress-indeterminate:after {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
    infinite;
    animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
    infinite;
    -webkit-animation-delay: 1.15s;
    animation-delay: 1.15s;
}

@-webkit-keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%;
    }
    60% {
        left: 100%;
        right: -90%;
    }
    100% {
        left: 100%;
        right: -90%;
    }
}

@keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%;
    }
    60% {
        left: 100%;
        right: -90%;
    }
    100% {
        left: 100%;
        right: -90%;
    }
}

@-webkit-keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%;
    }
    60% {
        left: 107%;
        right: -8%;
    }
    100% {
        left: 107%;
        right: -8%;
    }
}

@keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%;
    }
    60% {
        left: 107%;
        right: -8%;
    }
    100% {
        left: 107%;
        right: -8%;
    }
}

.holding > .ant-statistic-title{
    color: black;
    font-weight: bold;
    /* font-size: 18px !important; */
}